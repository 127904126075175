import ogImage from '../images/og.png';
import logo from '../images/logo.png';
import getText from '../locales';

const imageUrl = `https://evertool.app${ogImage}`;
const logoUrl = `https://evertool.app${logo}`;

const blogAuthor = {
  '@type': 'Organization',
  name: 'EverTool',
  url: 'https://evertool.app',
  logo: {
    '@type': 'ImageObject',
    url: logoUrl,
    width: '256',
    height: '256',
  },
};

export const genBlogJsonLd = ({
  url,
  headline,
  alternativeHeadline,
  image = imageUrl,
  inLanguage,
  articleBody = '',
  keywords,
  dateCreated,
  datePublished,
  dateModified,
  genre = ['Evernote', 'Markdown', 'EverTool'],
}) => ({
  '@context': 'http://schema.org',
  '@type': 'BlogPosting',
  image,
  url,
  headline,
  alternativeHeadline,
  dateCreated,
  datePublished,
  dateModified,
  inLanguage,
  publisher: blogAuthor,
  keywords,
  genre,
  articleBody,
  mainEntityOfPage: url,
  author: {
    '@type': 'Organization',
    name: 'EverTool',
    url: logoUrl,
  },
});

export const softwareJsonLd = () => ({
  '@context': 'https://schema.org',
  '@type': 'SoftwareApplication',
  name: 'EverTool-toolbox for Evernote',
  operatingSystem: 'OSX 10.13',
  applicationCategory: 'https://schema.org/UtilitiesApplication',
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '5',
    ratingCount: '30',
  },
  offers: {
    '@type': 'Offer',
    price: '5.00',
    priceCurrency: 'USD',
  },
});

export const genBreadcrumbJsonLd = (items) => {
  const itemListElement = [];
  let count = 1;
  items.forEach((item) => {
    itemListElement.push({
      '@type': 'ListItem',
      position: count,
      name: item.name,
      item: item.url,
    });
    count += 1;
  });

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};

export const genHowToUseBreadcrumbJsonLd = (title, url, locale) => {
  const howToUseTitle = getText(locale, 'howtouse:evertool');
  const splitUrl = url.split('/');
  const howToUseUrl = `${splitUrl.splice(0, splitUrl.length - 2).join('/')}/`;
  return genBreadcrumbJsonLd([
    {
      name: howToUseTitle,
      url: howToUseUrl,
    },
    {
      name: title,
      url,
    },
  ]);
};
