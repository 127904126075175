const bowser = require('bowser');
const urlJoin = require('url-join');

const allLocales = ['en', 'zh-tw', 'zh-cn', 'ja'];
const siteUrl = 'https://evertool.app';

exports.macOrWindows = () => {
  if (typeof window !== 'undefined') {
    const detect = bowser._detect(window.navigator.userAgent);
    if (detect.windows || detect.windowsPhone) {
      return 'win';
    }
  }
  return 'mac';
};

exports.isMobile = () => bowser.ios || bowser.android || bowser.blackberry || bowser.firefoxos;

exports.isNotWin10 = () => {
  if (typeof window !== 'undefined') {
    const detect = bowser._detect(window.navigator.userAgent);
    if (detect.windows && (detect.osversion === '7' || detect.osversion === '8')) {
      return true;
    }
  }
  return false;
};

exports.isChina = () => {
  if (typeof window !== 'undefined' && navigator.languages.includes('zh-CN')) {
    return true;
  }
  return false;
};

exports.removeTrailing = url => url.replace(/\/+$/, '');

const selfDefinedToFormalLocale = {
  en: 'en',
  'zh-cn': 'zh-Hans',
  'zh-tw': 'zh-Hant',
};

const withoutTrailing = url => url.slice(-1) !== '/';

exports.withoutTrailing = withoutTrailing;

const addTrailingSlash = (url) => {
  if (withoutTrailing(url)) {
    return `${url}/`;
  }
  return url;
};

exports.addTrailingSlash = addTrailingSlash;

exports.createHrefLangs = (currentLocale, url) => {
  const data = [];
  const splitUrl = url.replace('/zh-tw', '').replace('/zh-cn', '');
  allLocales.forEach((locale) => {
    if (locale === 'en') {
      data.push({
        lang: selfDefinedToFormalLocale[locale],
        href: addTrailingSlash(urlJoin(siteUrl, splitUrl)),
      });
      data.push({
        lang: 'x-default',
        href: addTrailingSlash(urlJoin(siteUrl, splitUrl)),
      });
    } else {
      data.push({
        lang: selfDefinedToFormalLocale[locale],
        href: addTrailingSlash(urlJoin(siteUrl, locale, splitUrl)),
      });
    }
  });
  return data;
};

exports.camelCased = str => str.replace(/-([a-z])/g, g => g[1].toUpperCase());
