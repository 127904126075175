const en = require('./en.json');
const zhTW = require('./zh-tw.json');
const zhCN = require('./zh-cn.json');
const ja = require('./ja.json');

module.exports = (locale, key) => {
  if (locale === 'zh-tw') {
    return zhTW[key];
  } if (locale === 'zh-cn') {
    return zhCN[key];
  } if (locale === 'ja') {
    return ja[key];
  }

  return en[key];
};
