import React from 'react';
import Helmet from 'react-helmet';
import urlJoin from 'url-join';
import ogImage from '../images/og.png';
import { withoutTrailing, addTrailingSlash } from '../utils';
import { softwareJsonLd } from '../utils/jsonld';

const defaultJSONLD = JSON.stringify(softwareJsonLd());

const SEO = ({
  title, subtitle, url, script, noscript, locale = 'en', hreflangs = [], vendorCSS = [], vendorJS = [], jsonld,
}) => {
  const imageUrl = `https://evertool.app${ogImage}`;

  return (
    <Helmet defer={false}>
      <html lang={locale} />
      <title>{title}</title>
      <meta name="description" content={subtitle} />
      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={subtitle} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:type" content="website" />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content="evertoolapp"
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={subtitle} />
      <meta name="twitter:image" content={imageUrl} />
      {hreflangs.map(item => (
        <link
          key={item.lang}
          rel="alternate"
          hrefLang={item.lang}
          href={item.href}
        />
      ))}
      {vendorCSS.map(css => (
        <link rel="stylesheet" key={css.href} href={css.href} />
      ))}
      {vendorJS.map(js => (
        <script key={js.src} src={js.src} />
      ))}
      {typeof window !== 'undefined' && withoutTrailing(window.location.pathname)
        ? <link rel="canonical" href={addTrailingSlash(urlJoin(window.location.origin, window.location.pathname))} />
        : null}
      {script
        ? (
          <script key="script" dangerouslySetInnerHTML={{ __html: script }} />
        )
        : null}
      <script type="application/ld+json">
        {defaultJSONLD}
      </script>
      {jsonld
        ? (
          <script type="application/ld+json">
            {JSON.stringify(jsonld)}
          </script>
        )
        : null}
      {noscript && (
        <noscript key="noscript" dangerouslySetInnerHTML={{ __html: noscript }} />
      )}
    </Helmet>
  );
};

export default SEO;
